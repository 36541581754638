import * as React from "react";
import Grid from "@mui/material/Grid";
import * as queryString from "query-string";
import CircularProgress from "@mui/material/CircularProgress";
import {useEffect, useState} from "react";
import {Collapse} from "@mui/material";
import {useTheme} from "@mui/system";
import Icon from "react-eva-icons";
import Text from "../../components/data-display/text";
import {auth} from "../../utils/firebase.utils";
import FormInput from "../../components/forms/form-input";
import SecondaryButton from "../../components/buttons/secondary-button";
import FormWrapper from "../../components/forms/form-wrapper";
import InlineLink from "../../components/data-display/inline-link";
import FormErrorText from "../../components/data-display/form-error-text";
import {Check} from "react-feather";
import {isQuickTaskProject, title} from "../../utils/constant.urls";
  
  

const Action = ({location}) => {
  const theme = useTheme();
  const styles = {
    background: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: "100vh",
      [theme.breakpoints.down("md")]: {
        justifyContent: "flex-start",
      },
    },
    card: {
      maxWidth: "450px",
      background: "white",
      margin: "32px auto",
      borderRadius: "6px",
      border: "1.5px solid rgb(240,240,240)",
      padding: "24px",
      boxSizing: "border-box",
      [theme.breakpoints.down("xs")]: {
        background: "transparent",
        border: "none",
      },
    },
    loading: {
      color: theme.palette.primary.main,
    },
  };
  let [loading, setLoading] = useState(true);
  let [mode, setMode] = useState(null);
  let [email, setEmail] = useState(null);
  let [code, setCode] = useState(null);
  let [reset, setReset] = useState(false);
  let [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [count, setCount] = useState(60);

  useEffect(() => {
    let queryData = queryString.parse(location.search);
    processAction(queryData);
  }, [location]);

  const processAction = (queryData) => {
    // Handle action.
    switch (queryData.mode) {
      case "resetPassword":
        if (queryData.oobCode) {
          auth
            .verifyPasswordResetCode(queryData.oobCode)
            .then(async (email) => {
              await setEmail(email);
              await setCode(queryData.oobCode);
              await setLoading(false);
              await setMode("resetPassword");
            })
            .catch(function (error) {
              console.log(error.message);
              setLoading(false);
              setError("Code is invalid or has expired.");
            });
        } else {
          setLoading(false);
          setError("Code is invalid or has expired.");
        }
        break;
      case "verifyEmail":
        if (queryData.oobCode)
          auth
            .applyActionCode(queryData.oobCode)
            .then(function (resp) {
              setLoading(false);
              setMode("verified");
            })
            .catch(function () {
              setLoading(false);
              setError("Code is invalid or has expired.");
            });
        else {
          setLoading(false);
          setError("Code is invalid or has expired.");
        }
        break;
      default:
        setError("Invalid Code");
        setLoading(false);
    }
  };

  const resetPassword = (values: Record<string, any>) => {
    const confirmPassword = values?.confirmPassword;
    const password = values?.password;
    return new Promise((resolve, reject) => {
      if (password === confirmPassword) {
        auth
          .confirmPasswordReset(code, password)
          .then(function (resp) {
            setReset(true);
          })
          .catch(function (error) {
            switch (error.code) {
              case "auth/expired-action-code":
              case "auth/invalid-action-code":
                reject("Code has expired.");
                break;
              case "auth/weak-password":
                reject({password: error.message});
                break;
              default:
                reject(error.message);
            }
          });
      } else {
        reject({confirmPassword: "Passwords don't match"});
      }
    });
  };

  return (
    <div
      style={{
        ...(styles.background as React.CSSProperties),
      }}
    >
      <Grid container justifyContent={"center"} component={"div"}>
        <Grid item xs={11} sm={10} md={8} lg={5} xl={5} component={"div"}>
          <div
            style={{
              ...(styles.card as React.CSSProperties),
            }}
          >
            <Collapse in={loading} unmountOnExit>
              <div style={{justifyContent: "center", display: "flex"}}>
                <CircularProgress
                  size={18.0}
                  thickness={10.0}
                  sx={{
                    "& .MuiCircularProgress-root": {
                      ...styles.loading,
                    },
                  }}
                />
              </div>
            </Collapse>

            <Collapse in={mode === "resetPassword" && !reset} unmountOnExit>
              <div style={{justifyContent: "flex-start", display: "flex", flexDirection: "column"}}>
                <FormWrapper onSubmit={resetPassword}>
                  <Text variant={"h6"} bold>
                    Reset Password
                  </Text>
                  <Text
                    variant={"body2"}
                    medium
                    style={{color: theme.palette.primary.main, marginBottom: "12px"}}
                  >
                    for {email}
                  </Text>
                  <FormInput
                    type={"password"}
                    name={"password"}
                    placeholder={"New Password"}
                    required
                  />
                  <FormInput
                    type={"password"}
                    name={"confirmPassword"}
                    placeholder={"Confirm Your Password"}
                    required
                  />
                  <div style={{height: "18px"}} />
                  <FormErrorText />
                  <SecondaryButton
                    background={theme.palette.primary.main}
                    textColor={"white"}
                    disabled={false}
                    style={{maxWidth: "unset", width: "100%", border: "2.5px solid #4E3EFD"}}
                    type={"submit"}
                  >
                    {"Change Password"}
                  </SecondaryButton>
                </FormWrapper>
              </div>
            </Collapse>
            <Collapse in={mode === "resetPassword" && reset} unmountOnExit>
              <div style={{alignItems: "center", display: "flex", flexDirection: "column"}}>
                <Check width={24} color={theme.palette.primary.main} />
                <Text
                  variant={"body2"}
                  style={{color: theme.palette.primary.main, marginTop: "8px"}}
                  medium
                >
                  Your Password has been reset.
                </Text>
              </div>
            </Collapse>
            <Collapse in={mode === "verified"} unmountOnExit>
              <div style={{alignItems: "center", display: "flex", flexDirection: "column"}}>
                <img
                  loading={"lazy"}
                  src={
                    "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Fsent.png?alt=media&token=91e934cf-cad3-4611-9ddf-22a629e327c6"
                  }
                  alt={"Email Verified for Sydetasker"}
                  height={250}
                />
                <Text variant={"body2"} style={{color: theme.palette.primary.main}} medium>
                  Your email has been verified.
                </Text>
              </div>
            </Collapse>
            <Collapse in={error !== null && error !== ""} unmountOnExit>
              <div style={{alignItems: "center", display: "flex", flexDirection: "column"}}>
                <Icon name={"alert-triangle"} size={"large"} fill={"rgb(234,84,105)"} />
                <Text variant={"body2"} style={{color: "rgb(198,53,89)", marginLeft: "8px"}} medium>
                  {error}
                </Text>
              </div>
            </Collapse>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Action;
